var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1"},[_vm._v(_vm._s('mdi-table-large'))]),_vm._v(" "+_vm._s(_vm.$t('project.header.columnMenu'))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","max-height":"900"}},[_c('v-card-title',[_c('h4',{staticStyle:{"color":"#183857"}},[_vm._v(_vm._s(_vm.$t('project.header.columnsConfiguration')))])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t('project.header.currentFields'))+" "),_c('v-text-field',{attrs:{"label":_vm.$t('project.search')},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),_c('v-virtual-scroll',{attrs:{"items":_vm.columns.filter(function (column) { return column.selected && !column.actions; }).filter(function (column) { return column.text[_vm.getLocale] ? column.text[_vm.getLocale].toLowerCase().includes(_vm.current.toLowerCase())
                : column.text['en-EN'].toLowerCase().includes(_vm.current.toLowerCase()); }
                ),"height":"400","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){item.selected = false}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text[_vm.getLocale] || item.text['en-EN'])+" ")]),_c('v-icon',{attrs:{"align":"end","color":"primary"}},[_vm._v(" "+_vm._s('mdi-chevron-right')+" ")])],1)]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.$t('project.header.availableFields'))+" "),_c('v-text-field',{attrs:{"label":_vm.$t('project.search')},model:{value:(_vm.available),callback:function ($$v) {_vm.available=$$v},expression:"available"}}),_c('v-virtual-scroll',{attrs:{"items":_vm.columns.filter(function (column) { return !column.selected && !column.actions; }).filter(function (column) { return column.text[_vm.getLocale] ? column.text[_vm.getLocale].toLowerCase().includes(_vm.available.toLowerCase())
                : column.text['en-EN'].toLowerCase().includes(_vm.available.toLowerCase()); }
                ),"height":"400","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){item.selected = true}}},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s('mdi-chevron-left')+" ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text[_vm.getLocale] || item.text['en-EN'])+" ")])],1)]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","color":"error"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v(_vm._s('mdi-close'))]),_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")],1),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.apply}},[_c('v-icon',[_vm._v(_vm._s('mdi-check'))]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }