export function setColumns(state, payload) {
    state.columns = payload
}

export function setCreatorFilter(state, payload) {
    state.creatorFilter = payload
}

export function setRightsFilter(state, payload) {
    state.rightsFilter = payload
}

export function setUsageTermsFilter(state, payload) {
    state.usageTermsFilter = payload
}

export function setError(state, payload) {
    state.isError = payload
}

export function setErrorMessage(state, payload) {
    state.errorMessage = payload
}

export function setSuccess(state, payload) {
    state.isSuccess = payload
}

export function setErrorDebug(state, payload) {
    state.errorDebug = payload
}

export function setDeepLinks(state, payload) {
    state.deepLinks = payload
}

export function setHeaderDrawer(state) {
    state.drawer = !state.drawer
}

export function setMarked(state, id) {
    state.marked.add(id)
}

export function addFilter(state, payload) {
    state.filters.push(payload)
}

export function setFilters(state, payload) {
    state.filters = payload
}

export function setFilterKeywords(state, payload) {
    state.filterKeywords = payload
}

export function setFilterChannels(state, payload) {
    state.filterChannels = payload
}

export function setFilterTypes(state, payload) {
    state.filterTypes = payload
}

export function setSelected(state, payload) {
    state.selected = payload
}

export function setFileUpload(state, payload) {
    state.fileUpload = payload
}

export function addFile(state, payload) {
    state.files.push(payload)
}

export function setSyncing(state, payload) {
    state.syncing = payload
}

export function addSyncing(state, payload) {
    state.syncing.push(payload)
}

export function setFiles(state, payload) {
    state.files = payload
}

export function removeColumn(state, payload) {
    state.columns = state.columns.filter(column => {
        return (column.text !== payload)
    })
}

export function setCurrentRequest(state, payload) {
    state.currentRequest = payload
}

export function setDataStructure(state, payload) {
    state.dataStructure = payload
}

export function setItemsPerPage(state, payload) {
    state.itemsPerPage = payload
}

export function setFiltersItems(state, payload) {
    state.filtersItems = payload
}

export function setTotal(state, payload) {
    state.total = payload
}

export function setPreview(state, payload) {
    state.preview = payload
}

export function loading(state, payload) {
    state.loading = payload
}

export function setLocale(state, payload) {
    state.locale = payload
}

export function addColumns(state, payload) {
    state.columns.push(...payload)
}

export function setUploadStrategy(state, payload) {
    state.uploadStrategy = payload
}

export function setUploadRefs(state, payload) {
    state.uploadRefs = payload
}

export function setConfig(state, payload) {
    state.imageConfig.form = payload
}

export function setImageConfig(state, payload) {
    state.imageConfig = payload
}

export function setNames(state, payload) {
    state.names = payload
}

export function setEditMetadata(state, payload) {
    state.editMetadata = payload
}

//FILES FOR UPLOAD
export function setUploadFile(state, files) {
    state.uploadedFiles = []
    files.forEach(file => {
        state.uploadedFiles.push({
            file,
            loaded: false,
            loading: false,
            error: false,
        })
    })
}

export function setUploadedFileLoading(state, index) {
    state.uploadedFiles[index].loading = !state.uploadedFiles[index].loading
}

export function setUploadedFileLoaded(state, index) {
    state.uploadedFiles[index].loaded = !state.uploadedFiles[index].loaded
}

export function setUploadedFileError(state, index) {
    state.uploadedFiles[index].error = !state.uploadedFiles[index].error
}

export function setUploadedFileExists(state, index) {
    state.uploadedFiles[index].exists = !state.uploadedFiles[index].exists
}

export function setBasic(state, payload) {
    state.basic = payload
}

export function clearFilters(state) {
    state.filterChannels = []
    state.filterTypes = []
    state.filterKeywords = []
}

export function setSelectedC2Files(state, payload) {
    state.selectedC2Files = payload
}