import axios from "axios";
import store from "../store";

const MEDIA_ENTRYPOINT = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8090';

const requests = [];

const axiosInstance = axios.create({
    withCredentials: false,
    baseURL: MEDIA_ENTRYPOINT
});

// attache auth header
axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('auth');
    config.headers.Authorization = token && config.url !== '/config' ? `Bearer ${token}` : '';
    return config;
});

// logout if token is invalid
axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        store.dispatch('main/logOut').then();
    }

    return Promise.reject(error);
});

//axiosInstance, axios
export default {
    MEDIA_ENTRYPOINT,
    get(name, path, config = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.get(path, config, {cancelToken: request.token});
    },
    put(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.put(path, data, {cancelToken: request.token});
    },
    post(name, path, data = {}, config = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.post(path, data, config, {cancelToken: request.token});
    },
    patch(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.patch(path, data, {cancelToken: request.token});
    },
    delete(path) {
        return axiosInstance.delete(path);
    },
    cancel(name) {
        const index = requests.findIndex((request) => request.name === name);

        if (index !== -1) {
            if (requests[index]?.request) {
                requests[index].request.cancel();
            }

            requests.splice(index, 1);
        }
    }
}
