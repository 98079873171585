//AUTH
export function isLoggedIn(state) {
    const token = localStorage.getItem('auth');
    state.loggedIn = token !== null && token !== undefined;
    return state.loggedIn;
}

//UI
export function getHeaderDrawer(state) {
    return state.drawer
}

export function getSyncing(state) {
    return state.syncing
}

export function getSyncError(state) {
    return state.syncError
}

export function getSuccess(state) {
    return state.isSuccess
}

export function getError(state) {
    return state.isError
}

export function getSyncRefresh(state) {
    return state.syncRefresh
}

export function getErrorMessage(state) {
    return state.errorMessage
}

export function getErrorDebug(state) {
    return state.errorDebug
}

//when image is uploading
export function isLoading(state) {
    return state.loading
}

//current language
export function getLocale(state) {
    return state.locale
}


//FILTERS
export function getFilters(state) {
    return state.filters
}

export function getFilterByIndex(state) {
    return (index) => {
        return state.filters[index]
    }
}

export function getColumns(state) {
    return state.columns
}

export function getFiles(state) {
    return state.files
}

export function getFiltersItems(state) {
    return state.filtersItems
}

export function getFilterKeywords(state) {
    return state.filterKeywords
}

export function getFilterChannels(state) {
    return state.filterChannels
}

export function getFilterTypes(state) {
    return state.filterTypes
}


//DATA TABLE
export function getSelected(state) {
    return state.selected
}

export function getChannels(state) {
    return state.channels
}

export function getCurrentRequest(state) {
    return state.currentRequest
}

export function getItemsPerPage(state) {
    return state.itemsPerPage
}

export function getProjects(state) {
    return state.projects
}

export function getTotal(state) {
    return state.total
}

//thumbnail image for data-table
export function getBase64Preview(state) {
    return state.base64Preview
}


//UPLOAD/ EDIT
export function getFileUpload(state) {
    return state.fileUpload
}

export function getPreview(state) {
    return state.preview
}

export function getUploadRefs(state) {
    return state.uploadRefs
}

export function getCurrentMetadata(state) {
    return state.currentMetadata
}

export function getEditMetadata(state) {
    return state.editMetadata
}

export function getDistincts(state) {
    return state.distincts
}

//names for columns
export function getNames(state) {
    return state.names
}

//CONFIG
export function getConfig(state) {
    return state.imageConfig
}

export function getBasic(state) {
    return state.basic
}

export function getDocumentConfig(state) {
    return state.documentConfig
}

//FILES
export function getUploadingFiles(state) {
    return state.uploadedFiles
}

export function getFileIndex(state) {
    return (x) => {
        return state.uploadedFiles[x]
    }
}

//DEEPLINKS
export function getDeepLinks(state) {
    return state.deepLinks
}

export function getLoading(state) {
    return state.filesLoading
}

export function getSelectedC2FilesLength(state) {
    let length = 0;
    state.selectedC2Files.forEach(entity => {
        length += entity.fileNames.length
    })
    return length
}

export function getSelectedC2Files(state) {
    return state.selectedC2Files
}