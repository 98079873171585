<template>
  <v-app :key="$store.getters['main/isLoggedIn']">
    <NavBar v-if="$store.getters['main/isLoggedIn']"/>

    <keep-alive>
      <v-main :class="($vuetify.theme.dark ? 'main-content--dark ' : 'main-content')">
        <v-container fluid class="pa-0" style="height:100%;background-color: #eeeeee">
          <router-view :key="$route.name + ($route.params.id || '')"></router-view>
        </v-container>
      </v-main>
    </keep-alive>
  </v-app>
</template>

<script>
import NavBar from "@/components/navigation/NavBar";
import Axios from "@/utility/axios";
import {mapActions} from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  components: {NavBar},
  data() {
    return {}
  },
  /*
  async created() {
    await this.loadConfig();
  },
   */
  async mounted() {
    await this.loadConfig()
  },
  methods: {
    async loadConfig() {
      const response = await Axios.get('auth/config', 'config').catch(err => {
      });

      if (!response) {
        alert('Authentication unavailable');
        return;
      }

      process.oauthServer = response.data.oauthServerUrl
      process.webimagesUrl = response.data.webimagesUrl
    }
  }
}
</script>

<style lang="scss">
.v-btn {
  text-transform: none !important;
}
</style>