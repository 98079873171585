import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)

/*
const routes = [
  {
    name: 'NotSupportedBrowser',
    path: '/browserError',
    component: () => import('@/layouts/NotSupportedBrowser')
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/layouts/LoginLayout')
  },
  {
    name: 'Dashboard',
    path: '/',
    component: () => import('@/layouts/OutLayout'),
    redirect: '/projects',
    beforeEnter: authGuard,
    children: [
      {
        path: '/projects',
        name: 'Projects',
        component: () => import('@/views/Dashboard')
      }
    ]
  },
  {
    path: '/projects/:id',
    name: 'Home',
    beforeEnter: authGuard,
    component: () => import('@/layouts/MainLayout'),
    props: true,
    redirect: '/projects/:id',
    children: [
      {
        path: '',
        name: 'CurrentProject',
        component: () => import('@/views/Projects'),
        props: true
      },
      {
        path: '/settings',
        name: 'ConfigSettings',
        component: () => import('@/views/ConfigSettings'),
        props: false,
      },
      {
        path: 'marked',
        name: 'Marked',
        component: () => import('@/views/Projects'),
        props: true,
      },
      {
        path: 'archived',
        name: 'Archived',
        component: () => import('@/views/Projects'),
        props: true,
      },
      {
        path: 'deleted',
        name: 'Deleted',
        component: () => import('@/views/Projects'),
        props: true,
      },
      {
        path: 'selected',
        name: 'Selected',
        component: () => import('@/views/Projects'),
        props: true,
      },
      {
        path: 'downloads',
        name: 'Recently Downloads',
        component: () => import('@/views/Downloads'),
        props: true,
      },
      {
        path: 'upload',
        name: 'Upload',
        component: () => import('@/views/Upload'),
        props: true
      },
      {
        path: 'edit/:imgId',
        name: 'Edit',
        component: () => import('@/views/Upload'),
        props: true
      },
      {
        path: ':filter',
        component: () => import('@/views/Projects'),
        props: true
      },
    ]
  },
  {
    path: '*',
    name: 'Error404',
    component: () => import('@/views/Error404')
  }
]
 */

const routes = [
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/layouts/LoginLayout'),
        beforeEnter: (to, from, next) => {
            if (store.getters['main/isLoggedIn']) {
                next('/projects');
                return;
            }

            next();
        }
    },
    {
        path: '/',
        meta: {
            requiresAuth: true
        },
        redirect: 'projects'
    },
    {
        path: '/projects',
        meta: {
            requiresAuth: true
        },
        props: true,
        component: () => import('@/components/misc/PlainRouterView'),
        children: [
            {
                path: '',
                component: () => import('@/views/Dashboard'),
            },
            {
                path: ':id',
                props: true,
                component: () => import('@/components/misc/PlainRouterView'),
                children: [
                    {
                        name: 'overview',
                        path: '',
                        component: () => import('@/views/FilesOverview'),
                    },
                    {
                        path: 'marked',
                        name: 'Marked',
                        component: () => import('@/views/FilesOverview'),
                        props: true,
                    },
                    {
                        path: 'archived',
                        name: 'Archived',
                        component: () => import('@/views/FilesOverview'),
                        props: true,
                    },
                    {
                        path: 'deleted',
                        name: 'Deleted',
                        component: () => import('@/views/FilesOverview'),
                        props: true,
                    },
                    {
                        path: 'selected',
                        name: 'Selected',
                        component: () => import('@/views/FilesOverview'),
                        props: true,
                    },
                    {
                        path: 'downloads',
                        name: 'Recently Downloads',
                        component: () => import('@/views/Downloads'),
                        props: true,
                    },
                    /*
                    {
                        path: 'upload',
                        name: 'Upload',
                        component: () => import('@/views/Upload'),
                        props: true
                    },
                     */
                    {
                        path: 'edit/:img',
                        name: 'EditImage',
                        component: () => import('@/views/EditImage'),
                        props: true
                    },
                    {
                        path: ':filter',
                        component: () => import('@/views/FilesOverview'),
                        props: true
                    }
                ]
            }
        ]
    },
    {
        name: 'ConfigSettings',
        path: '/settings',
        meta: {
            requiresAuth: true
        },
        component: () => import('@/views/ConfigSettings'),
        props: false,
    },
    {
        name: 'NotSupportedBrowser',
        path: '/browserError',
        component: () => import('@/layouts/NotSupportedBrowser')
    },
    {
        path: '*',
        name: 'Error404',
        component: () => import('@/views/Error404')
    }
]

const router = new VueRouter({
    mode: 'hash',
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['main/isLoggedIn']) {
            next('/login');
            return;
        }
    }

    next();
});

export default router
