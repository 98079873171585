export default function () {
    return {
        loggedIn: false,

        locale: 'de-DE',
        syncRefresh: new Date().getTime(),
        isSuccess: false,
        isError: false,
        errorMessage: '',
        errorDebug: '',
        filesLoading: false,
        names: [],
        syncing: [],
        syncError: [],
        loading: false,
        fileUpload: null,
        uploadStrategy: '',
        deepLinks: [],
        currentMetadata: {},
        editMetadata: {},
        uploadRefs: {},
        preview: '',
        base64Preview: '',
        selected: [],
        selectedC2Files: [],
        itemsPerPage: 50,
        total: 15,
        uploadedFiles: [],
        channels: [],
        projects: [],
        projectId: '',
        drawer: true,
        distincts: [],
        columns: [],
        basicNames:
            {
                indexName: 'Basic', label: {'en-EN': 'Basic', 'de-DE': ''}, name: 'Basic', names: [
                    {indexName: 'dc.title', label: {'en-EN': 'Title', 'de-DE': ''}, name: 'title'},
                    {indexName: 'dc.subject', label: {'en-EN': 'Keywords', 'de-DE': ''}, name: 'subject'},
                    {
                        indexName: 'plus.CopyrightOwner.CopyrightOwnerName',
                        label: {'en-EN': 'Copyright owner Name', 'de-DE': ''},
                        name: 'CopyrightOwnerName'
                    },
                    {indexName: 'dc.creator', label: {'en-EN': 'Creator', 'de-DE': ''}, name: 'creator'},
                    {
                        indexName: 'dc.description',
                        label: {'en-EN': 'Caption/Description', 'de-DE': ''},
                        name: 'description'
                    },
                    {indexName: 'dc.rights', label: {'en-EN': 'Copyright Notice', 'de-DE': ''}, name: 'rights'},
                ]
            },
        basic: [],
        marked: new Set(),
        files: [],
        currentRequest: {},
        filters: [],
        filtersItems: {},
        filterChannels: [],
        filterTypes: [],
        filterKeywords: [],
        creatorFilter: [],
        rightsFilter: [],
        usageTermsFilter: [],
        imageConfig: {
            id: 0,
            thumbnail: '',
            info: {
                name: '',
                marked: false,
                deleted: false,
                archived: false,
                type: 'IMAGE',
                selected: false,
                channels: [],
                projectId: ''
            },
            form: []
        },
        documentConfig: {
            id: 0,
            thumbnail: '',
            info: {
                name: '',
                marked: false,
                deleted: false,
                archived: false,
                type: 'DOCUMENT',
                selected: false,
                channels: [],
                projectId: '',
                uploadDate: '',
            },
            form: [
                {
                    "name": "dc",
                    "xmpSpecName": "DocumentInfo",
                    "label": {
                        "en-EN": "Document Info",
                        "de-DE": ""
                    },
                    "definition": {'en-EN': 'document info'},
                    "helpText": {'en-EN': 'document info'},
                    "enabled": true,
                    "type": "GROUP",
                    "priority": "0",
                    "fields": [
                        {
                            "name": "title",
                            "label": {
                                'en-EN': "Title",
                                'de-DE': ''
                            },
                            "xmpSpecName": "dc:title",
                            "indexName": "DocumentInfo.title",
                            "type": "TEXT",
                            "formType": "field",
                            "items": [],
                            "definition": {
                                'en-EN': "Title for the document",
                                'de-DE': ""
                            },
                            "enabled": true,
                            "priority": 0,
                            "helpText": {
                                'en-EN': "Enter title",
                                'de-DE': ""
                            },
                            "fields": [],
                            "value": ""
                        },
                    ]
                }
            ]
        }
    }
}
