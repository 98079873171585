<template>
  <v-app-bar app :flat="flat">

    <v-row class="pa-1 ma-0">
      <slot>
        <!-- waiting for buttons components -->
      </slot>
    </v-row>

    <v-spacer></v-spacer>
    <!-- profile menu and language select -->
    <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        max-width="195"
        tile
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn elevation="0" tile :ripple="{center: true}"
               v-bind="attrs" v-on="on"
               class="pr-0 mr-2">
          <div class="subtitle-2 pr-0 text--disabled">
            {{ name }}
          </div>
          <v-avatar
              class="pr-0"
          >
            <v-icon color="primary" class="pr-0">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card style="padding: 0" tile>
        <v-list class="pa-0 ma-0" dense link tile>
          <v-list-item-group
              color="primary"
          >
            <v-list-item to="/settings" exact>
              <v-list-item-content>
                <v-list-item-title v-text="$t('appHeader.settings')"></v-list-item-title>
              </v-list-item-content>

            </v-list-item>
            <v-list-item @click="logOut">

              <v-list-item-content>
                <v-list-item-title v-text="$t('appHeader.logout')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <v-select :items="Object.keys($i18n.messages)"
              style="max-width: 70px"
              hide-details
              @input="locale"
              :value="getLocale"
    >
      <template v-slot:selection="{item}">
        <v-img contain :src="require('../assets/flags/'+item+'.svg')"></v-img>
      </template>

      <template v-slot:item="{item}">
        <v-img  max-width="50" contain :src="require('../assets/flags/'+item+'.svg')"></v-img>
      </template>
    </v-select>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'AppHeader',
  props: ['drawer', 'flat'],
  data() {
    return {
      name: localStorage.getItem('name')
    }
  },
  computed: {
    ...mapGetters({
      getLocale: 'main/getLocale'
    })
  },
  methods: {
    ...mapActions({
      logOut: 'main/logOut'
    }),
    ...mapMutations({
      setDrawer: 'main/setHeaderDrawer',
      setLocale: 'main/setLocale'
    }),
    locale(value) {
      this.setLocale(value)
      this.$i18n.locale = value;
      this.$vuetify.lang.current = value;
    }
  },
}

</script>