import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/lib/locale/de';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    customProperties: true,
    lang: {
        locales: {de, en},
        current: 'de'
    },
    theme: {
        themes: {
            light: {
                primary: '#183857',
                secondary: '#ac965c',
                accent: '#608ecb',
                error: '#ff0000',
                info: '#0066ff',
                success: '#4CAF50',
                warning: '#FFC107',
            }
        }
    }
});
