export default {
  name: 'Name',
  original: 'Original',
  noFiles: 'No Files',
  browser: {
    notSupported: 'Current browser is not supported',
    message: "The browser you are currently using is not supported. It seems like it's not using modern technologies, so you might be interested in more modern browser. Here is a link for Microsoft's official browser Edge.",
    edgeDownload: 'Download Edge'
  },
  info: {
    header: 'How to use',
    content: `
    <h5>Priority:</h5>
    <p>Priority should be used for showing certain fields in Basic section. The higher priority means the field will be displayed higher. If all fields of the parent have their priority set (more then 0), then parent would not be displayed in it is own section and will be shown only in Basic.</p>
    <h5>Toggle</h5>
    <p>Toggle columns can turn off/on certain fields. Toggling off parent will disable all of it children and vise-versa. Toggled off fields would not be shown at all (even if they got priority set). Toggling off the whole group will remove it from expansion panels (in media collection) or will remove the tab (in publication planner and cockpit).</p>
`
  },
  settings: {
    toggle: 'Toggle',
    priority: 'Priority',
    name: 'Name'
  },
  messages: {
    somethingWentWrong: 'Something went wrong!',
    uploadedSuccessfully: 'Uploaded successfully!',
    updatedSuccessfully: 'Updated successfully'
  },
  toggleQuestion: 'Do you really want to unselect all files?',
  deleting: {
    question: 'Do you really want to delete this file? | Do you really want to delete these files?'
  },
  archiving: {
    question: 'Do you want to archive this file? | Do you want to archive these files?'
  },
  restoring: {
    question: 'Do you want to restore this file? | Do you want to restore these files?'
  },
  routesNames: {
    CurrentProject: 'Current Project',
    Marked: 'Marked',
    Archived: 'Archived',
    Deleted: 'Deleted',
    Selected: 'Selected'
  },
  back: 'Back',
  save: 'Save',
  apply: 'Apply',
  close: 'Close',
  reset: 'Reset',
  cancel: 'Cancel',
  copy: 'Copy',
  rewrite: 'Rewrite',
  ignore: 'Ignore',
  ignored: 'Ignored',//
  allFiles: 'All Files',
  errors: 'Errors',
  warnings: 'Warnings',
  basic: 'Basic',
  no: 'No',
  yes: 'Yes',
  dashboard: {
    toProject: 'Go to project'
  },
  mainLayout: {
    dashboard: 'Dashboard',
    project: 'Project',
    fileLibrary: 'File library',
    markedFiles: 'Marked files',
    favouriteFiles: 'Favourite files',
    recentDownloads: 'Recent downloads',
    recentDeleted: 'Recently deleted',
    archived: 'Archived',
    filters: 'Filters'
  },
  appHeader: {
    profile: 'Profile',
    logout: 'Logout',
    settings: 'Metadata Settings',
  },
  project: {
    header: {
      columnMenu: 'column view',
      columnsConfiguration: 'Columns configuration',
      availableFields: 'Available fields',
      currentFields: 'Current fields',
      sync: 'Sync',
      upload: {
        title: 'Upload',
        chooseType: 'Choose Upload Type',
        directory: 'Directory',
        file: 'File',
        chooseDirectory: 'Choose a directory',
        multipleFiles: 'Multiple Files',
        chooseFiles: 'Choose files',
        duplicateOptions: 'Duplicate Options',
        chooseImage: 'Choose an image',
        pickAnImage: 'Pick an image',
        yourImage: 'Your image',
        createCopy: 'Create Copy',
        rewriteFile: 'Rewrite File'
      },
      download: {
        title: 'Download',
        chooseSize: 'Choose a size',
        original: 'Original',
        large: 'Large',
        medium: 'Medium',
        small: 'Small',
        other: 'Other',
        height: 'Height',
        width: 'Width',
        chooseColorProfile: 'Choose a color profile',
        selectFromC2: 'Select From KnowledgeGraph',
        licenseText: 'Only images with documented rights usage terms are displayed.'
      },
      channels: {
        title: 'channels',
        assign: 'Assign files to channels',
      },
      archive: {
        title: 'Download Zip',
        titleImg: 'Archive this image',
        items: 'No items selected | One item selected | {count} items selected',
        label: 'Click here to download',
        placeholder: 'Nothing to download',
        enterZipName: 'Enter archive name'
      },
      removeFiles: 'remove files',
      restoreFiles: 'restore files',
      archiveFiles: 'archive files'
    },
    folders: {
      folders: 'Folders',
      allFiles: 'all files',
      recentlyAdded: 'recently added'
    },
    filter: {
      filter: 'Filter',
      type: 'type',
      typeHint: 'Choose types',
      channel: 'channel',
      channelHint: 'Choose channels',
      keyword: 'keyword',
      keywordHint: 'Choose keywords',
      apply: 'apply filter',
      save: 'save this filter',
      reset: 'reset all filters',
      chooseName: 'Choose filter name',
      rights: 'Copyright Notice',
      rightsHint: 'Choose Copyright Notices',
      creator: 'Creator',
      creatorHint: 'Choose Creators',
      cpNotice: 'Rights Usage Terms',
      cpNoticeHint: 'Choose Rights Usage Terms',
    },
    search: 'Search',
    dataTable: {
      headers: {
        actions: 'actions',
        filename: 'filename',
        title: 'keywords'
      },
      itemsPerPage: 'Items per page'
    }
  },
  upload: {
    fileAlreadyExists: 'File already exists',
    title: 'Upload',
    preview: 'Preview',
    saveIptc: 'Save IPTC info to file',
    markFile: 'Mark File'
  },
  recentDownloads: {
    title: 'Recent Downloads',
    date: 'Date',
    name: "Name",
    numberOfItems: 'Number of Items',
    link: 'Link',
    copyLink: 'Copy Link'
  }
}