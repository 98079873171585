import VueI18n from 'vue-i18n'
import Vue from 'vue';
import de from './languages/de'
import en from './languages/en'

Vue.use(VueI18n)

const messages = {
 'en-EN':en,
 'de-DE':de,
}

export default new VueI18n({
  locale: 'en-EN', // set locale
  messages, // set locale messages
})

