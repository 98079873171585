export default {
    name: 'Name',
    original: 'Original',
    noFiles: 'Keine Dateien',
    settings: {
        toggle: 'Umschalten',
        priority: 'Priorität',
        name: 'Name'
    },
    info: {
        header: 'Wie zu verwenden',
        content: `
    <h5>Priorität:</h5>
    <p>Die Priorität sollte für die Anzeige bestimmter Felder im Basisbereich verwendet werden. Je höher die Priorität, desto höher wird das Feld angezeigt. Wenn alle Felder des übergeordneten Feldes eine Priorität (größer als 0) haben, wird das übergeordnete Feld nicht in seinem eigenen Bereich angezeigt, sondern nur im Basisbereich.</p>
    <h5>Toggle</h5>
    <p>Toggle-Spalten können bestimmte Felder ein-/ausschalten. Wenn Sie das übergeordnete Feld ausschalten, werden alle seine untergeordneten Felder deaktiviert und umgekehrt. Ausgeschaltete Felder werden überhaupt nicht angezeigt (auch wenn sie Priorität haben). Wenn Sie die gesamte Gruppe ausschalten, wird sie aus den Erweiterungsfeldern (in der Mediensammlung) entfernt oder die Registerkarte (im Publikationsplaner und im Cockpit) wird entfernt.</p>
`
    },
    toggleQuestion: 'Möchten Sie wirklich alle Dateien abwählen?',
    messages: {
        somethingWentWrong: 'Etwas ist schief gelaufen!',
        uploadedSuccessfully: 'Erfolgreich hochgeladen!',
        updatedSuccessfully: 'Erfolgreich geupdated'
    },
    deleting: {
        question: 'Möchten Sie diese Datei wirklich löschen? | Möchten Sie diese Dateien wirklich löschen?'
    },
    archiving: {
        question: 'Möchten Sie diese Datei archivieren? | Möchten Sie diese Dateien archivieren?'
    },
    restoring: {
        question: 'Möchten Sie diese Datei wiederherstellen? | Möchten Sie diese Dateien wiederherstellen?'
    },
    routesNames: {
        overview: 'Alle Dateien',
        marked: 'Markiert',
        archived: 'Archiviert',
        deleted: 'Gelöscht',
        selected: 'Ausgewählt'
    },
    back: 'zurück',
    save: 'Sichern',
    apply: 'Anwenden',
    close: 'Schließen',
    reset: 'Zurücksetzen',
    cancel: 'Abbrechen',
    copy: 'Kopieren',
    rewrite: 'Datei überschreiben',
    ignore: 'Ignore', //
    ignored: 'Ignoriert',
    allFiles: 'alle Dateien',
    errors: 'Fehler',
    warnings: 'Warnings',//
    basic: 'Basic',//
    no: 'Nein',
    yes: 'Ja',
    dashboard: {
        toProject: 'Zum Projekt '
    },
    mainLayout: {
        dashboard: 'Dashboard',
        project: 'Projekt',
        fileLibrary: 'Verzeichnis',
        markedFiles: 'Markierte Dateien',
        favouriteFiles: 'Favouriten',
        recentDownloads: 'Letzte Downloads',
        recentDeleted: 'Kürzlich gelöscht',
        archived: 'Archiviert',
        filters: 'Filter'
    },
    appHeader: {
        profile: 'Profil',
        logout: 'Abmelden',
        settings: 'Metadata Settings', //
    },
    project: {
        header: {
            columnMenu: 'Spaltenansicht',
            columnsConfiguration: 'Spaltenkonfiguration',
            availableFields: 'Verfügbare Felder',
            currentFields: 'Aktuelle Felder',
            sync: 'Sync', //
            upload: {
                title: 'Hochladen',
                chooseType: 'Art des Uploads',
                directory: 'Directory',
                file: 'Datei',
                chooseDirectory: 'Choose a directory', //
                multipleFiles: 'Mehrere Dateien',
                chooseFiles: 'Datei(en) auswählen',
                duplicateOptions: 'Doppelte Bilder verwalten',
                chooseImage: 'Bild',
                pickAnImage: 'Bild auswählen',
                yourImage: 'Ihr Bild',
                createCopy: 'Kopie erstellen',
                rewriteFile: 'Datei neu schreiben'
            },
            download: {
                title: 'Herunterladen',
                chooseSize: 'Größe festlegen',
                original: 'Original',
                large: 'Groß',
                medium: 'Mittel',
                small: 'Klein',
                other: 'Andere',
                height: 'Höhe',
                width: 'Breite',
                chooseColorProfile: 'Wählen Sie ein Farbprofil',
                selectFromC2: 'Wählen Sie aus KnowledgeGraph',
                licenseText: 'Es werden nur Bilder angezeigt deren Nutzungsbedingungen im Knowledge Graph hinterlegt sind.'
            },
            channels: {
                title: 'Kanäle',
                assign: 'Kanal zuweisen',
            },
            archive: {
                title: 'Zip herunterladen',
                titleImg: 'Dieses Bild archivieren',
                items: 'Keine Elemente ausgewählt | Ein Element ausgewählt | {count} Elemente ausgewählt',
                label: 'Zum Download hier klicken',
                placeholder: 'Keine Daten zum Download gewählt',
                enterZipName: 'Archivname eingeben'
            },
            removeFiles: 'Dateien entfernen',
            restoreFiles: 'Dateien wiederherstellen',
            archiveFiles: 'Dateien archivieren'
        },
        folders: {
            folders: 'Ordner',
            allFiles: 'alle Dateien',
            recentlyAdded: 'kürzlich hinzugefügt'
        },
        filter: {
            filter: 'Filter',
            type: 'Typ',
            typeHint: 'Typen auswählen',
            channel: 'Kanal',
            channelHint: 'Kanäle auswählen',
            keyword: 'Schlüsselwort',
            keywordHint: 'Schlüsselwörter auswählen',
            apply: 'Filter anwenden',
            save: 'Filter speichern',
            reset: 'Alle Filter zurücksetzen',
            chooseName: 'Wählen Sie den Filternamen',
            rights: 'Copyright-Vermerk',
            rightsHint: 'Copyright-Vermerk auswählen',
            creator: 'Ersteller',
            creatorHint: 'Ersteller auswählen',
            cpNotice: 'Nutzungsbedingungen',
            cpNoticeHint: 'Nutzungsbedingungen auswählen',
        },
        search: 'Suche',
        dataTable: {
            headers: {
                actions: 'Aktionen',
                filename: 'Dateiname',
                title: 'Keywords'
            },
            itemsPerPage: 'Artikel pro Seite'
        }
    },
    upload: {
        fileAlreadyExists: 'Die Datei existiert bereits',
        title: 'Hochladen',
        preview: 'Vorschau',
        saveIptc: 'IPTC Informationen speichern',
        markFile: 'Datei auswählen'
    },
    recentDownloads: {
        title: 'Letzte Downloads',
        date: 'Datum',
        name: "Name",
        numberOfItems: 'Anzahl der Teile',
        link: 'Verknüpfung',
        copyLink: 'Link kopieren'
    }
}