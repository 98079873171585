<template>
  <v-dialog
      v-model="dialog"
      width="650"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          tile
          text
          v-bind="attrs"
          v-on="on"
      >
        <v-icon class="pr-1">{{ 'mdi-table-large' }}</v-icon>
        {{ $t('project.header.columnMenu') }}
      </v-btn>
    </template>
    <v-card tile max-height="900">
      <v-card-title>
        <h4 style="color: #183857">{{ $t('project.header.columnsConfiguration') }}</h4>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col md="6">
            {{ $t('project.header.currentFields') }}
            <v-text-field :label="$t('project.search')" v-model="current"></v-text-field>
            <v-virtual-scroll
                :items="columns.filter(column => column.selected && !column.actions).filter(column =>
                  column.text[getLocale] ? column.text[getLocale].toLowerCase().includes(current.toLowerCase())
                  : column.text['en-EN'].toLowerCase().includes(current.toLowerCase())
                  )"
                height="400"
                item-height="50"
            >
              <template v-slot:default="{item}">
                <v-list-item @click="item.selected = false">
                  <v-list-item-title>
                    {{ item.text[getLocale] || item.text['en-EN'] }}
                  </v-list-item-title>
                  <v-icon align="end" color="primary">
                    {{ 'mdi-chevron-right' }}
                  </v-icon>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
          <v-col md="6">
            {{ $t('project.header.availableFields') }}
            <v-text-field :label="$t('project.search')" v-model="available"></v-text-field>
            <v-virtual-scroll
                :items="columns.filter(column => !column.selected && !column.actions).filter(column =>
                    column.text[getLocale] ? column.text[getLocale].toLowerCase().includes(available.toLowerCase())
                  : column.text['en-EN'].toLowerCase().includes(available.toLowerCase())
                  )"
                height="400"
                item-height="50"
            >
              <template v-slot:default="{item}">
                <v-list-item @click="item.selected = true">
                  <v-icon class="pr-3" color="primary">
                    {{ 'mdi-chevron-left' }}
                  </v-icon>
                  <v-list-item-title>
                    {{ item.text[getLocale] || item.text['en-EN'] }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            tile
            color="error"
            @click="reset"
        >
          <v-icon>{{ 'mdi-close' }}</v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            tile
            color="success"
            @click="apply"
        >
          <v-icon>{{ 'mdi-check' }}</v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'ColumnMenu',
  data: () => ({
    dialog: false,
    current: '',
    available: '',
    columns: []
  }),
  watch: {
    dialog(value) {
      this.columns = JSON.parse(JSON.stringify(this.getColumns))
      if (!value) {
        this.reset()
      }
    },
  },
  methods: {
    ...mapActions({
      uploadColumns: 'main/uploadColumns',
      getFiles: 'main/getFiles'
    }),
    ...mapMutations({
      setColumns: 'main/setColumns',
    }),

    apply() {
      this.dialog = false;
      let parsedColumns = JSON.parse(JSON.stringify(this.columns))
      this.setColumns(parsedColumns)
      const arr = parsedColumns.filter(column => column.selected).map(column => {
        return column.value
      });

      this.getFiles();
      this.uploadColumns(arr)
    },
    reset() {
      this.dialog = false
      this.columns = JSON.parse(JSON.stringify(this.getColumns))
    }
  },
  computed: {
    ...mapGetters({
      getColumns: 'main/getColumns',
      getLocale: 'main/getLocale'
    }),
  },
  beforeMount() {
    this.columns = JSON.parse(JSON.stringify(this.getColumns))
  }
}
</script>