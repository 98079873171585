import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import i18n from './plugins/internationalization'
import uploader from 'vue-simple-uploader'
import md5 from 'md5'
import {LMap, LTileLayer, LMarker, LControl} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';
import browserDetect from "vue-browser-detect-plugin";
import {Integrations} from "@sentry/tracing";
import * as Sentry from '@sentry/vue';

Vue.use(browserDetect);

// Sentry logging
Sentry.init({
    Vue,
    logErrors: true,
    environment: process.env.NODE_ENV || "unknown_env",
    dsn: "https://a51f922425204afabe169bb8c683df15@sentry.we2p.com/17",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control', LControl);

Vue.prototype.createObjectURL = window.URL.createObjectURL
Vue.prototype.md5 = md5

Vue.use(uploader);
Vue.use(CKEditor);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');



