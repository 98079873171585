<template>
  <nav>
    <v-snackbar
        content-class="text-center text-subtitle-2"
        color="error"
        rounded
        timeout="-1"
        v-model="isError"
    >
      <p>{{ errorDebug }}</p>
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="isError = false"
        >
          <v-icon>
            {{ 'mdi-close' }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <v-snackbar v-model="isSuccess" color="success" content-class="text-center text-subtitle-2">
      {{ $t('messages.updatedSuccessfully') }}
    </v-snackbar>

    <v-navigation-drawer
        permanent
        app
    >
      <v-list-item>
        <!-- EXTERNAL LINKS -->
        <v-list-item-icon>
          <menu-layout></menu-layout>
        </v-list-item-icon>

        <v-list-item-content style="padding-top: 0; padding-bottom: 0">
          <v-img max-width="120px" contain src="../../assets/venus_media_collection.svg">
          </v-img>
          <!--<v-list-item-subtitle style="padding-left: 4px">Media Collection</v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>


      <!-- ENTRIES -->
      <v-list
          :key="id"
          dense
          nav
      >
        <v-list-item to="/projects" exact>
          <v-list-item-icon>
            <v-icon>{{ 'mdi-home' }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('mainLayout.dashboard') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ 'mdi-view-dashboard' }}</v-icon>
          </v-list-item-icon>

          <v-list-item-action style="margin-bottom: 0; margin-left: 0">
            <v-select
                hide-details
                :label="$t('mainLayout.project')"
                :value="projects.find(project => project.id === $router.currentRoute.params.id)"
                :menu-props="{ offsetY: true }"
                :items="projects"
            >
              <template v-slot:item="{ item }">
                <v-list-item
                    :to="'/projects/' + item.id"
                >
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:selection="{ item }">
                <span>{{ item.name }}</span>
              </template>
            </v-select>
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="$router.currentRoute.params.id"
                     v-for="item in menuListItems"
                     :key="item.title"
                     :to="`/projects/${id}${item.path ? `/${item.path}` : ''}`"
                     link
                     exact
        >
          {{ item.title }}

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.translation) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="!$router.currentRoute.path.match(/(?:upload|edit\/[0-9a-z]*\/$)|(settings$)/g) && filters.length"
            prepend-icon="mdi-filter"
            :value="false"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('mainLayout.filters') }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="(filter,i) in filters"
              :key="i"
              @click="setFilter(i)"
          >
            <v-list-item-title>
              {{ filter.name }}
            </v-list-item-title>
            <v-list-item-action>
              <v-btn
                  icon
                  @click="removeFilter(filter)"
              >
                <v-icon>
                  {{ 'mdi-close' }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-if="onEdit()">
        <v-divider></v-divider>
        <v-list-group v-for="group in names"
                      no-action
                      group="main"
                      v-if="group.enabled"
                      :key="group.name">
          <template v-slot:activator v-if="group.names">
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ group.label[getLocale] || group.label['en-EN'] }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-divider></v-divider>
          <v-list dense>
            <template v-for="field in group.names" v-if="field.enabled">
              <v-list-group v-if="field.names"
                            no-action
                            :key="field.indexName"
                            group="sub">
                <template v-slot:activator v-if="field.names">
                  <v-list-item-content>
                    <v-list-item-title class="pl-2">{{ field.label[getLocale] || field.label['en-EN'] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list>
                  <template v-for="subField in field.names" v-if="subField.enabled">
                    <v-list-group v-if="subField.names"
                                  no-action
                                  :key="subField.indexName"
                                  sub-group>
                      <template v-slot:activator v-if="subField.names">
                        <v-list-item-content>
                          <v-list-item-title class="">{{ subField.label[getLocale] || subField.label['en-EN'] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                          v-for="lastField in subField.names"
                          link
                          v-if="lastField.enabled"
                          @click="focusOn(lastField.indexName.replaceAll('.','_'))"
                          :key="lastField.name">
                        <v-list-item-content>
                          <v-list-item-title class="">
                            {{ lastField.label[getLocale] || lastField.label['en-EN'] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        link
                        @click="focusOn(subField.indexName.replaceAll('.','_'))"
                        :key="subField.name">
                      <v-list-item-content>
                        <v-list-item-title class="pl-4">
                          {{ subField.label[getLocale] || subField.label['en-EN'] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list-group>
              <v-list-item
                  class="pl-6"
                  v-else
                  link
                  @click="focusOn(field.indexName.replaceAll('.','_'))"
                  :key="field.name">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ field.label[getLocale] || field.label['en-EN'] }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-divider></v-divider>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import ColumnMenu from "@/components/ColumnMenu";
import AppHeader from "@/components/AppHeader";
import {mapActions, mapGetters, mapMutations} from "vuex";
import MenuLayout from "@/components/MenuLayout"

export default {
  name: 'NavBar',
  components: {
    MenuLayout,
    ColumnMenu,
    AppHeader
  },
  data() {
    return {
      id: undefined,
      menuListItems: [
        {
          translation: 'mainLayout.fileLibrary',
          icon: 'mdi-image-multiple',
          path: ''
        },
        {
          translation: 'mainLayout.favouriteFiles',
          icon: 'mdi-star',
          path: 'marked'
        },
        {
          translation: 'mainLayout.recentDownloads',
          icon: 'mdi-briefcase-download',
          path: 'downloads'
        },
        {
          translation: 'mainLayout.archived',
          icon: 'mdi-archive',
          path: 'archived'
        },
        {
          translation: 'mainLayout.recentDeleted',
          icon: 'mdi-delete',
          path: 'deleted'
        },
        {
          translation: 'mainLayout.markedFiles',
          icon: 'mdi-check',
          path: 'selected'
        }
      ],
      imageData: {}
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      this.id = this.$router.currentRoute.params.id;
      this.selected = [];

      if (newVal.name !== 'EditImage' && oldVal.name !== "EditImage")
        this.clearFilters();

      this.projectChange();
    },

    /*
    id(value, prev) {
      if (this.projects.find(({id}) => id === value)) {
        if (this.$router.currentRoute.name === 'Edit') {
          this.$router.push('/projects/' + this.id)
        }
        this.projectChange()
      } else {
        if (this.$router.currentRoute.path.includes('projects'))
          this.$router.replace('/404')
      }
    },
     */
    getLocale(value) {
      this.$i18n.locale = value;
      this.$vuetify.lang.current = value;
      this.selected = [];
      this.projectChange();
    }
  },
  async created() {
    this.id = this.$router.currentRoute.params.id;

    this.setError(false)
    await this.browserCheck();
    await this.loadProjects();

    await this.loadConfiguration();

    await this.loadColumns();
    //await this.loadUserSettings();

    await this.loadChannels(this.id);

    this.$i18n.locale = this.getLocale;
    this.$vuetify.lang.current = this.getLocale;
    //this.projectChange();
  },
  computed: {
    ...mapGetters({
      getLocale: 'main/getLocale',
      filters: 'main/getFilters',
      projects: 'main/getProjects',
      uploadRefs: 'main/getUploadRefs',
      getConfig: 'main/getConfig',
      names: 'main/getNames',
      getSelected: 'main/getSelected',
      getError: 'main/getError',
      errorMessage: 'main/getErrorMessage',
      errorDebug: 'main/getErrorDebug',
      getSuccess: 'main/getSuccess'
    }),
    isError: {
      get() {
        return this.getError
      },
      set(value) {
        this.setError(value)
      }
    },
    isSuccess: {
      get() {
        return this.getSuccess
      },
      set(value) {
        this.setSuccess(value)
      }
    },
    selected: {
      get() {
        return this.getSelected
      },
      set(value) {
        this.setSelected(value)
      }
    },
  },
  methods: {
    ...mapActions({
      browserCheck: 'main/browserCheck',
      loadFilters: 'main/loadFilters',
      setFilter: 'main/setFiltersById',
      removeFilter: 'main/removeFilter',
      loadProjects: 'main/loadProjects',
      loadColumns: 'main/loadColumns',
      loadChannels: 'main/loadChannels',
      configParse: 'main/configParse',
      loadConfiguration: 'main/loadConfiguration',
      loadUserSettings: 'main/loadUserColumns',
      uploadColumns: 'main/uploadColumns',
    }),
    ...mapMutations({
      setError: 'main/setError',
      setSelected: 'main/setSelected',
      setSuccess: 'main/setSuccess',
      clearFilters: 'main/clearFilters'
    }),
    projectChange() {
      this.loadFilters();

      // update columns
      this.loadColumns();
      this.loadUserSettings();

      this.loadChannels(this.id)
    },
    focusOn(target) {
      const formattedTarget = target.replace(':', '_')
      const expansions = formattedTarget.split('_')
      const group = this.uploadRefs[expansions[0]][0]
      if (!group._data.isActive) {
        group.toggle()
      }
      if (expansions.length > 2) {
        const subGroup = this.uploadRefs[expansions[0] + '_' + expansions[1]][0]
        if (!subGroup._data.isActive && subGroup.toggle) {
          subGroup.click()
        }
      }
      if (expansions.length > 3) {
        const thirdGroup = this.uploadRefs[expansions[0] + '_' + expansions[1] + '_' + expansions[2]][0]
        if (!thirdGroup._data.isActive && thirdGroup.toggle) {
          thirdGroup.click()
        }
      }
      const element = this.uploadRefs[formattedTarget][0]
      setTimeout(() => {
        this.$vuetify.goTo(element, {offset: 100})
        if (element.focus) {
          element.focus()
        }
      }, 150)
    },

    onEdit() {
      return !!(this.$router.currentRoute.path.match(/\/upload/gm)
          || this.$router.currentRoute.path.match(/\/edit/gm))
    }
  }
}
</script>

<style lang="sass">
.v-list-item__action > .v-text-field > .v-input__control > .v-input__slot:before
  border-style: none

.v-list-item:hover:before
  color: #183857 !important

.v-list-item:focus:before
  color: #183857 !important

.v-list > .v-list-item > .v-list-item__action > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections
  flex-wrap: nowrap
</style>