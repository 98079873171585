<template>
  <v-menu
      offset-y
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon>
          {{ 'mdi-view-sequential' }}
        </v-icon>
      </v-btn>
    </template>

    <v-card class="">
      <v-list>
        <v-list-item href="https://c2.we2p.de/cockpit/">
          <v-list-item-icon>
            <v-icon color="primary"> {{'mdi-help-circle'}} </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Knowledge Graph Handbuch
          </v-list-item-title>
        </v-list-item>

        <v-list-item href="https://pp.we2p.de/cockpit/">
          <v-list-item-icon>
            <v-icon color="primary"> {{'mdi-vector-triangle'}} </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Publication Planner
          </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/projects">
          <v-list-item-icon>
            <v-icon color="primary"> {{'mdi-image-multiple'}} </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Media-Collection
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
name: "MenuLayout.vue"
}
</script>