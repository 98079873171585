var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","flat":_vm.flat}},[_c('v-row',{staticClass:"pa-1 ma-0"},[_vm._t("default")],2),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","max-width":"195","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pr-0 mr-2",attrs:{"elevation":"0","tile":"","ripple":{center: true}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"subtitle-2 pr-0 text--disabled"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-avatar',{staticClass:"pr-0"},[_c('v-icon',{staticClass:"pr-0",attrs:{"color":"primary"}},[_vm._v(" mdi-account-circle ")])],1)],1)]}}])},[_c('v-card',{staticStyle:{"padding":"0"},attrs:{"tile":""}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"dense":"","link":"","tile":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":"/settings","exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('appHeader.settings'))}})],1)],1),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('appHeader.logout'))}})],1)],1)],1)],1)],1)],1),_c('v-select',{staticStyle:{"max-width":"70px"},attrs:{"items":Object.keys(_vm.$i18n.messages),"hide-details":"","value":_vm.getLocale},on:{"input":_vm.locale},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","src":require('../assets/flags/'+item+'.svg')}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"max-width":"50","contain":"","src":require('../assets/flags/'+item+'.svg')}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }